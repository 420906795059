import { useUserLogout } from "@igloo-be-omnipartners/hooks";
import React, { useEffect } from "react";
import { withPage } from "../lib/withPage";

const LogoutPage = () => {
  const logout = useUserLogout();
  useEffect(() => {
    logout();
  }, [logout]);
  return <div />;
};

export default withPage(LogoutPage);
